var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"row justify-content-between mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama Departemen"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('div',[_c('b-table',{staticClass:"mt-3",style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":"sm"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '15%' : '' })})})}},{key:"cell(is_satusehat_upload)",fn:function(data){return [_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(data.item.is_satusehat_upload == 1 && data.item.is_need_sync == 0 ? "Terkirim" : ""))]),(
                    data.item.is_need_sync == 1 &&
                    data.item.is_satusehat_upload == 1
                  )?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Perlu Singkronisasi")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(_vm.btn)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-success btn-satusehat",class:data.item.is_need_sync == 1 &&
                    data.item.is_satusehat_upload == 1
                      ? 'pr-1'
                      : '',attrs:{"size":"sm","title":"Sinkronisasi Satu Sehat"},on:{"click":function($event){return _vm.syncSatuSehat(data.item)}}},[_c('i',{staticClass:"fas fa-sync px-0"}),_c('sup',[(
                        data.item.is_need_sync == 1 &&
                        data.item.is_satusehat_upload == 1
                      )?_c('i',{staticClass:"fas fa-circle text-danger fa-xs px-0"}):_vm._e()])]):_vm._e(),(_vm.btn && data.item.is_satusehat_upload == 0)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-success btn-satusehat ml-1 pr-1",attrs:{"size":"sm"}},[_c('b-form-checkbox',{attrs:{"id":`id-${data.item.id}`,"name":`name-${data.item.id}`,"value":"1","unchecked-value":"0"},on:{"change":function($event){return _vm.selectData($event, data.item, data.index)}},model:{value:(data.item.is_ss_checked),callback:function ($$v) {_vm.$set(data.item, "is_ss_checked", $$v)},expression:"data.item.is_ss_checked"}})],1):_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }