<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <!-- title card -->

        <Card>
          <template v-slot:body>
            <b-tabs content-class="mt-3">
              <b-tab title="Rekam Medis yang sudah diposting" active> 
                <ReturnMedicalRecord />
              </b-tab>
              <b-tab title="Dokter">
                <ReturnDoctor />
              </b-tab>
              <b-tab title="Ruangan">
                <ReturnRoom />
              </b-tab>
              <b-tab title="Gedung">
                <ReturnBuilding />
              </b-tab>
              <b-tab title="Departmen">
                <ReturnDepartemen />
              </b-tab>
              <b-tab title="Rekam Medis yang tidak akan diposting"> 
                <MedicalRecordExclude />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ReturnMedicalRecord from "@/component/satu-sehat/ReturnMedicalRecord.vue";
import MedicalRecordExclude from "@/component/satu-sehat/MedicalRecordExclude.vue";
import ReturnDepartemen from "@/component/satu-sehat/ReturnDepartemen.vue";
import ReturnBuilding from "@/component/satu-sehat/ReturnBuilding.vue";
import ReturnRoom from "@/component/satu-sehat/ReturnRoom.vue";
import ReturnDoctor from "@/component/satu-sehat/ReturnDoctor.vue";
export default {
  components: {
    Card,
    ReturnMedicalRecord,
    MedicalRecordExclude,
    ReturnDepartemen,
    ReturnBuilding,
    ReturnRoom,
    ReturnDoctor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satu Sehat" },
      { title: "Dashboard" },
    ]);
  },
};
</script>

<style>
</style>